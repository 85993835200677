<template>
    <div>
        <breadcrumb
            name="Test Drive"
            title="Settings"
            subtitle="View"
            @onTitleClicked="goBack"
        />
        <vx-card title="Details" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Model Name</td>
                                <td class="p-4">{{ item.model.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Branch Name</td>
                                <td class="p-4">{{ item.branch.name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Starts At</td>
                                <td class="p-4">{{ item.start_at }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Days</td>
                                <td class="p-4">{{ getDays(item.days) }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Branch Address
                                </td>
                                <td class="p-4">{{ item.branch.address }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Ends At</td>
                                <td class="p-4">{{ item.end_at }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vs-button
            color="primary"
            type="filled"
            style="float: right"
            @click="goBack"
            >Back
        </vs-button>
        <div class="clr" />
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";
import ChooseModel from "../../../components/custom/choose-model";

export default {
    components: { ChooseModel, Breadcrumb },
    name: "add-settings",
    data() {
        return {
            id: this.$route.params.id,
            days: [
                { day: "Sunday", value: 1 },
                { day: "Monday", value: 2 },
                { day: "Tuesday", value: 3 },
                {
                    day: "Wednesday",
                    value: 4
                },
                { day: "Thudsday", value: 5 },
                { day: "Friday", value: 6 },
                { day: "Saturday", value: 7 }
            ],
            chosenDays: [],
            branches: [],
            model_id: "",
            branch_id: "",
            start_at: "",
            end_at: "",
            item: {},
            modelCounter: 0
        };
    },
    methods: {
        getData() {
            this.$vs.loading();

            this.$httpAdmin
                .get(`test-drives/${this.id}`)
                .then(r => {
                    console.log(r);
                    this.$vs.loading.close();
                    this.item = r.data.data;
                    console.log({ item: this.item });
                    this.model_id = this.item.model.id;
                    this.modelCounter++;
                    this.branch_id = this.item.branch.id;
                    this.chosenDays = this.item.days;
                    this.start_at = this.item.start_at;
                    this.end_at = this.item.end_at;

                    const timezone = Intl.DateTimeFormat().resolvedOptions()
                        .timeZone;

                    console.log({ timeZone: timezone });

                    console.log({ start_at: this.start_at });
                    console.log({ end_at: this.end_at });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't load Data!",
                        color: "danger"
                    });
                });
        },
        getDays(days) {
            let s = "";
            for (let i in days) {
                for (let x in this.days) {
                    if (this.days[x].value == days[i]) {
                        s += this.days[x].day + " ,";
                    }
                }
            }
            s = s.substring(0, s.length - 2);
            return s;
        },
        setModel(model_id) {
            this.model_id = model_id;
        },
        setBrand(brand_id) {
            this.brand_id = brand_id;
        },
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                text: "You are going to save these data!",
                color: "primary",
                accept: () => this.saveData(),
                type: "confirm"
            });
        },
        goBack() {
            this.$router.push("/test-drive-settings");
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get(`/brands`)
                .then(res => {
                    this.brands = res.data.data.brands;
                    this.$vs.loading.close();
                    console.log(res);
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger"
                    });
                });
        },
        timeConvert(time) {
            time = time
                .toString()
                .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

            if (time.length > 1) {
                time = time.slice(1);
                time[5] = +time[0] < 12 ? " AM" : " PM";
                time[0] = +time[0] % 12 || 12;
                if (time[0] < 10) {
                    time[0] = "0" + time[0];
                }
            }
            return time.join("");
        },
        pushItem(array) {
            array.push({
                start_at: "",
                end_at: ""
            });
        },
        deleteItem(array, index) {
            array.splice(index, 1);
        },
        saveData() {
            this.$vs.loading();

            // if (this.start_at > this.end_at &&
            //       this.end_at.toString().toLowerCase() !== "00:00" &&
            //       this.end_at.toString().toLowerCase() !== "12:00 am" &&
            //       this.end_at.toString().toLowerCase() !== "12:00am"
            // ) {
            //   this.$vs.notify({
            //     title: "Error",
            //     text: "\"Start At\" time must be earlier than \"End At\" time!",
            //     color: "danger"
            //   });
            //   this.$vs.loading.close();
            //   return;
            // }

            if (!/^\d{2}[:]\d{2}\s[AP][M]$/.test(this.start_at)) {
                this.$vs.notify({
                    title: "Error",
                    text: '"Start At" time format is incorrect!',
                    color: "danger"
                });
                this.$vs.loading.close();
                return;
            }

            if (!/^\d{2}[:]\d{2}\s[AP][M]$/.test(this.end_at)) {
                this.$vs.notify({
                    title: "Error",
                    text: '"End At" time format is incorrect!',
                    color: "danger"
                });
                this.$vs.loading.close();
                return;
            }

            let payload = {
                model_id: this.model_id,
                branche_id: this.branch_id,
                days: this.chosenDays,
                start_at: this.timeConvert(this.start_at),
                end_at: this.timeConvert(this.end_at)
            };

            this.$httpAdmin
                .patch(`/test-drives/${this.id}`, payload)
                .then(r => {
                    console.log(r);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Data Updated Successfully",
                        color: "success"
                    });
                    this.goBack();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Couldn't Update Data!",
                        color: "danger"
                    });
                });
        },
        validateEmail(email) {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        getBranches() {
            this.$vs.loading();
            this.$httpBranches
                .get(`/branches`)
                .then(res => {
                    let total = res.data.data.total;

                    this.$httpBranches
                        .get(`/branches?per_page?${total}`)
                        .then(res => {
                            let branches = res.data.data.data;

                            for (let i in branches) {
                                for (let x in branches[i].types) {
                                    if (branches[i].types[x] == 1) {
                                        this.branches.push(branches[i]);
                                    }
                                }
                            }

                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: "Something Error",
                                text: "Couldn't fetch data from the server",
                                color: "danger"
                            });
                        });
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger"
                    });
                });
        }
    },
    watch: {
        brand_id(value) {
            for (let i in this.brands) {
                if (this.brands[i].id === value) {
                    this.brand_name = this.brands[i].name_en;
                    break;
                }
            }
        }
    },
    computed: {
        invalidForm() {
            if (
                this.chosenDays.length !== 0 &&
                this.model_id !== "0" &&
                this.branch_id !== "0" &&
                this.start_at &&
                this.end_at
            ) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        this.getBrands();
        this.getBranches();
        this.getData();
    }
};
</script>

<style scoped lang="scss">
.body {
    border-radius: 10px;
    padding: 20px;
}

.optionBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.days {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.input {
    width: 90%;
}

.emails {
    height: 175px;
}

label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
}

select {
    width: 45%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.body {
    background-color: #fff;
    padding-top: 20px;
}
</style>
